import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import ContentChannelsView from '../views/content/channels.view';
import ContentVideosView from '../views/content/videos.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import ContentCategoriesView from '../views/content/categories.view';
import ContentSequenceView from '../views/content/sequence.view';
import {
  DEFAULT_CHANNEL,
  SHOW_ADMIN_USERS,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_VIDEOS,
  SHOW_OVERVIEW_DASHBOARD,
} from '../config/const.config';

const AppRouter = ({ isLoggedIn }) => {
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    if (SHOW_OVERVIEW_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.DASHBOARD,
        element: <DashboardView />,
        exact: true,
      });
    }

    if (!DEFAULT_CHANNEL) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CHANNELS,
        element: <ContentChannelsView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_CATEGORIES) {
      routeMappings.push({
          path: ROUTE_PATH.CONTENT_CATEGORIES,
          element: <ContentCategoriesView />,
          exact: true,
        });
    }

    if (SHOW_CONTENT_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView videoContentType="videos" />,
        exact: true,
      });
    }

    if (DEFAULT_CHANNEL) {
      routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE,
          element: <ContentSequenceView />,
          exact: true,
        });
    }

    if (SHOW_ADMIN_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AppRouter;
